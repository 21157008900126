import React, {Component} from "react";
import {Navbar} from "react-bootstrap";
import NavigationMenu from "./NavigationMenu";
import Utilities from "../Util/Utilities";

export default class Header extends Component{
    state = {
        client_id: null
    };

    componentDidMount() {
        let util = new Utilities();
        util.getClientId().then(
            res => {this.setState({'client_id': res})}
        );
    }

    render() {return (
        <Navbar bg="light" expand="xs">
            <Navbar.Brand href="/home">SmsService - {this.state.client_id}</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                  <NavigationMenu />
            </Navbar.Collapse>
        </Navbar>
    )}
}
