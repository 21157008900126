import axios from 'axios';
import {fetchMessagesCount} from "./fetchMessageCount";
import {Auth} from "@aws-amplify/auth";

const URL = "https://wq3w552yk1.execute-api.us-east-1.amazonaws.com/dev/sms-proxy";

async function verifyCanSendMessage() {
    return fetchMessagesCount().then(res => {
        const data = res.data['Items'][0]
        let message_total = data.message_total['N'];
        let message_cap = data.message_cap['N'];
        return !(message_cap - message_total <= 0);
    })
}

async function sendMessage(message) {
    return await axios({
        method: 'post',
        url: URL,
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
        params: {
            TopicArn: "arn:aws:sns:us-east-1:709141584615:yoursip-dev-sms-send"
        },
        data: message
    });
}

export const pushMessage = async (state, token) => {
    let client_id = await Auth.currentSession().then(
        res => {
            return res.getAccessToken().payload['cognito:groups'][0]
        })
        .catch(
            error => {console.log(error);}
        );

    let message = {
        data: {
            "to": state.messageTo,
            "message": state.messageData,
            "client_id": client_id
        }
    };

    // return {success:true, data:sendMessage(message)}

    return await verifyCanSendMessage().then(
      res => {
          if (res){
              return {success:true, data:sendMessage(message)}
          }else{
              return {success: false, message: "You don't have messages left."}
          }
      }
    );
};

