import {Component} from "react";
import {Auth} from "@aws-amplify/auth";


export default class Utilities extends Component{
    state = {
        client_id: null,
        message_from: null
    };

    componentDidMount() {

    }

    async getClientId() {
        return await Auth.currentSession()
            .then(
                res => {
                    return res.getAccessToken().payload['cognito:groups'][0];
                }
            )
            .catch(
                error => {
                    console.log(error);
                    return false
                }
            );
    }
}
