import axios from "axios";
import { Auth } from "@aws-amplify/auth";

const URL =
  "https://nq1ycagjya.execute-api.us-east-1.amazonaws.com/prd/get-messages";

export const fetchMessages = async () => {
  let client_id = await Auth.currentSession()
    .then((res) => {
      return res.getAccessToken().payload["cognito:groups"][0];
    })
    .catch((error) => {
      console.log(error);
    });

  return await axios.post(URL, {
    client_id,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin" : "*", // Required for CORS support to work
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  });
};
