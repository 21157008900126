import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import Amplify from "aws-amplify";
import awsmobile from "./internal-config";
import About from "./Views/About";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./Views/Home";
import Outbound from "./Components/Outbound";
import Inbound from "./Components/Inbound";
import Conversation from "./Components/Conversation";
import ConversationList from "./Components/ConversationList";

Amplify.configure(awsmobile);
require('dotenv').config();

function App() {
  return (
    <div className="flex-wrap flex-column h-100">
      <Router>
        <Header />

        <div className="p-3">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/home" component={Home} />
            <Route path="/outbound" component={Outbound} />
            <Route path="/conversations" component={ConversationList} />
            <Route path="/conversation/:phone_number" component={Conversation} />
            <Route path="/inbound" component={Inbound} />
            <Route path="/about" component={About} />
          </Switch>
        </div>

        {/*<Footer />*/}
      </Router>
    </div>
  );
}

export default withAuthenticator(App);
