import React from "react";

function Footer() {
    return (
        <footer className="bg-light text-center p-1 absolute fixed-bottom mt-2">
            <small>&copy; Copyright 2020</small>
        </footer>
    )
}

export default Footer;
