import axios from 'axios';
import {Auth} from "@aws-amplify/auth";


const URL = "https://wq3w552yk1.execute-api.us-east-1.amazonaws.com/prd/sms-get-count";

export const fetchMessagesCount = async () => {
    let client_id = await Auth.currentSession().then(
        res => {return res.getAccessToken().payload['cognito:groups'][0]}
    )
    .catch(
        error => {console.log(error);}
    );

    return await axios({
        method: 'post',
        url: URL + "/" + client_id,
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
            // "Access-Control-Allow-Origin" : "*", // Required for CORS support to work
            'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
    });
};
