import React, {useState, useEffect} from "react";
import "react-chat-elements/dist/main.css";
import Input, {ChatList} from "react-chat-elements";
import {fetchMessages} from "../api/fetchMessages";
import moment from "moment";
import {useHistory} from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import Swal from 'sweetalert2'
import validator from 'validator'
import useSound from 'use-sound';
import soundURL from '../Sounds/sound.wav';


const ConversationList = () => {
    const [messages, setMessages] = useState({});
    const [playSound, setPlaySound] = useState(false);
    const history = useHistory()

    useEffect(() => {
        fetchData().then(r => console.log('Messages fetched'));
        setInterval(() => fetchData(), 10000);
    }, []);

    const fetchData = async () => {
        const result = (await fetchMessages()).data;
        const mappedResult = result.map(res => {
            for (const [key] of Object.entries(res)) {
                const mostRecentMessage = res[key][res[key].length - 1];
                let time = moment(mostRecentMessage.time).subtract(4, 'hours').format("YYYY-MM-DD H:mm:ss");
                if (mostRecentMessage.read === 'false'){
                    setPlaySound(true);
                }
                return {
                    avatar: 'https://kctherapy.com/wp-content/uploads/2019/09/default-user-avatar-e1569863570634.png',
                    title: key,
                    subtitle: mostRecentMessage.message,
                    date: new Date(time),
                    unread: mostRecentMessage.read === 'false' ? 1 : 0
                }
            }
        })
        mappedResult.sort((a, b) => (a.unread === 0 || b.unread === 1) ? 1 : -1);
        setMessages(mappedResult);
    };

    let validatePhoneNumber = (number) => {
        const isValidPhoneNumber = validator.isMobilePhone(number)
        return (isValidPhoneNumber)
    }

    function handleButtonClick() {
        Swal.fire({
            title: 'What\'s the phone number?',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Submit',
            allowOutsideClick: () => !Swal.isLoading(),
            inputValidator: (value) => {
                if (!validatePhoneNumber(value)) {
                    return 'You need to write a valid phone number'
                }
            }
        }).then((result) => {
            if (result.isConfirmed && result.value !== '') {
                let regex = /^[+1]{2}/g;
                if (!result.value.match(regex)) {
                    result.value = '+1' + result.value
                }
                history.push('/conversation/' + result.value)
            }
        })
    }

    const [play] = useSound(
        soundURL,
        { volume: 0.25 }
    );

    if (playSound){
        play()
        setPlaySound(false);
    }

    return (
        <div>
            <div>
                <ReactBootstrap.Button variant="primary" onClick={handleButtonClick}>
                    New Message
                </ReactBootstrap.Button>
            </div>
            <div>
                <b>Your Conversations:</b><br></br>
                {messages.length > 0 ? (
                    <ChatList
                        dataSource={messages}
                        onClick={(item) => {
                            history.push('/conversation/' + item.title)
                        }}
                    />
                ) : (
                    <div>Loading</div>
                )}
            </div>
        </div>
    );
};

export default ConversationList;
