import React, {useState, useEffect, useRef} from "react";
import "react-chat-elements/dist/main.css";
import {MessageList, Input, Button} from "react-chat-elements";
import {useParams} from "react-router-dom";
import {fetchMessages} from "../api/fetchMessages";
import {sendMessage, pushMessage} from "../api/pushMessage";
import {useHistory} from 'react-router-dom';
import {Auth} from "aws-amplify";
import moment from "moment";
import {markMessagesRead} from "../api/markMessagesRead";

const Conversation = () => {
    const history = useHistory()
    const [messages, setMessages] = useState({});
    const [messageData, setMessageData] = useState({});
    const [messageTo, setMessageTo] = useState('');
    const [alertObj, setAlertObj] = useState({show: false, message: '', type: ''});
    let {phone_number} = useParams()

    useEffect(() => {
        fetchData().then(r => {
            scrollToBottom();
            // Now mark this messages as read
            updateConversation();
        });
        let timer1 = setInterval(() => fetchData().then(r => {
            scrollToBottom();
            // Now mark this messages as read
            updateConversation();
        }), 10000);
    }, []);

    const updateConversation = () => {
        markMessagesRead(phone_number).then(r => console.log('Success!'));
    }

    const bottomRef = useRef();

    const scrollToBottom = () => {
        bottomRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    const fetchData = async () => {
        const result = (await fetchMessages()).data;

        let conversationMessages = []

        // Get the conversation we are interested in...
        result.map((res) => {
            if (typeof res[phone_number] !== "undefined") {
                conversationMessages.push(res[phone_number])
            }
        })

        let mappedResult = {}

        if (conversationMessages.length > 0) {
            // Get the actual objects for the text messages...
            mappedResult = Object.values(conversationMessages[0]).map((message) => {
                let time = moment(message.time).subtract(4, 'hours').format("YYYY-MM-DD H:mm:ss");
                return {
                    title: message.from,
                    text: message.message,
                    date: new Date(time),
                    position: message.direction === "out" ? "right" : "left",
                    type: message.media == null ? "text" : message.media.length > 0 ? "photo" : "text",
                    data: {
                        uri: message.media,
                        status: {
                            click: true,
                            loading: 0,
                        }
                    },
                    className: "jaseiscoolclass",
                }
            });
            setMessageTo(phone_number)
        } else {
            // This is a new conversation
            setMessageTo(phone_number)
        }
        setMessages(mappedResult);
    };

    return (
        <div>
            <div>
                Messaging: {messageTo}
            </div>
            <br></br>
            <div>
                {messages.length > 0 ? (
                    <MessageList
                        className="message-list"
                        lockable={true}
                        toBottomHeight={"100%"}
                        dataSource={messages}
                        onClick={(e) => {
                            if (e.data.uri != null) {
                                window.open(e.data.uri, "_blank")
                            }
                        }}
                    />
                ) : messageTo.length > 0 ? (
                    <div>Start the conversation</div>
                ) : (
                    <div>Loading</div>
                )}
                <Input placeholder="Type here..."
                       multiline={true}
                       onChange={e => setMessageData(e.target.value)}
                       rightButtons={
                           <Button
                               onClick={() => {
                                   let state = {
                                       "messageData": messageData,
                                       "messageTo": messageTo,
                                       "alertObj": alertObj
                                   }
                                   let token = Auth.currentSession()
                                       .then(
                                           res => {
                                               return res.getAccessToken().getJwtToken()
                                           }
                                       ).catch(err => console.log(err));

                                   pushMessage(state, token)
                                       .then(
                                           res => {
                                               if (res.success) {
                                                   setTimeout(function () {
                                                       window.location.reload(false);
                                                   }, 500);
                                               } else {
                                                   console.log('Error...')
                                               }
                                           }
                                       ).catch(err => {
                                       console.log(err);
                                       this.setState({
                                           alertObj: {
                                               show: true,
                                               type: 'danger',
                                               message: 'We could not send that message.'
                                           }
                                       });
                                       this.clearAlert();
                                   });
                               }}
                               color='white'
                               backgroundColor='black'
                               text='Send'/>
                       }/>
            </div>
            <div ref={bottomRef} className="list-bottom"/>
        </div>
    );
};

export default Conversation;
