import React, {Component} from "react";
import {Container} from "react-bootstrap";
import { fetchMessages } from '../api/fetchMessages';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

export default class Inbound extends Component{
    state = {
        messageData: [],
    };

    componentDidMount() {
        fetchMessages().then(response => response.data['Items'])
            .then((data) => {
                let test = data.map(item => (
                    {   "time":item['time'].S,
                        "from":item['from'].S,
                        "text":item['message'].S,}
                ));
                this.setState({messageData: test})
            } )
    }

    render() {
        return(
            <Container fluid className="pb-3">
                <BootstrapTable
                    keyField="time"
                    data={this.state.messageData}
                    columns={columns}
                    pagination={paginationFactory()}
                />
            </Container>
        );
    }
}

const columns = [
    {
        dataField: 'time',
        text: 'Date'
    },
    {
        dataField: 'from',
        text: 'Message from'
    },
    {
        dataField: 'text',
        text: 'Message'
    }
];
