import React from "react";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import { Nav } from "react-bootstrap";
import { auth } from "./Auth/auth";

function showMenu() {
  return (
    <Nav className="mr-auto">
      <Nav.Link href="/home">Home</Nav.Link>
      <Nav.Link href="/conversations">Messages</Nav.Link>
      <Nav.Link href="/inbound">About Messaging</Nav.Link>
      <AmplifySignOut button-text="Logout" />
    </Nav>
  );
}

function NavigationMenu() {
  if (auth.isAuthenticated) {
    return showMenu();
  } else {
    return <div>You need to log in to see this content</div>;
  }
}

export default NavigationMenu;
