import React, { Component } from "react";
import {Alert, Button, Form } from "react-bootstrap";
import {pushMessage} from "../api/pushMessage";
import {Auth} from "aws-amplify";


export default class Outbound extends Component{
    state = {
        messageData: '',
        messageTo: '',
        alertObj: {show: false, message: '', type: ''},
    };

    clearAlert() {
        setTimeout(() => {
            this.setState({alertObj:{show:false, type:'', message:''}})
        }, 5000);
    }

    resetForm(){
        this.setState({messageTo:''});
        this.setState({messageData:''});
    }

    handleSubmit(state){
        let token = Auth.currentSession()
            .then(
                res => {return res.getAccessToken().getJwtToken()}
            ).catch(err => console.log(err));

        pushMessage(state, token)
            .then(
                res => {
                    if (res.success){
                        this.setState({alertObj:{show:true, type:'success', message:'Successfully sent message!'}});
                    }else{
                        this.setState({alertObj:{show:true, type:'danger', message:res.message}});
                    }
                    this.resetForm();
                    this.clearAlert();
                }
            ).catch(err => {
                console.log(err);
                this.setState({alertObj:{show:true, type:'danger', message:'We couldn\'t send that message.'}});
                this.clearAlert();
            });
    }

    render() {
        return(
            <div>
                <Alert variant={this.state.alertObj.type} show={this.state.alertObj.show}>
                    {this.state.alertObj.message}
                </Alert>

                <Form>
                    <Form.Group controlId="formMessageTo">
                        <Form.Label>Message To</Form.Label>
                        <Form.Control
                            type="text"
                            value={this.state.messageTo}
                            placeholder="Enter number here"
                            onChange={e => this.setState({messageTo: e.target.value})}
                        />
                    </Form.Group>

                    <Form.Group controlId="formMessageText">
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={this.state.messageData}
                            rows="3"
                            placeholder="Enter message here"
                            onChange={e => this.setState({messageData: e.target.value}) }
                        />
                    </Form.Group>

                    <Button
                        variant="primary"
                        block
                        onClick={() => this.handleSubmit(this.state)}
                    >
                        Send
                    </Button>
                </Form>
            </div>
        );
    }
}
