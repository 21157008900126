import React, {Component} from "react";
import {fetchMessagesCount} from "../api/fetchMessageCount";
import Utilities from "../Util/Utilities";


export default class Home extends Component {
    constructor(props) {
        super(props);
        this.test = new Utilities(props);
    }

    state = {
        message_cap: 0,
        message_total: 0,
        message_count: 0,
        client_id: null
    };

    componentDidMount() {
        this.getMessageCount();
    }

    getMessageCount() {
        fetchMessagesCount().then(
            res => {
                let message_cap = res.data.Items[0].message_cap['N'];
                let message_total = res.data.Items[0].message_total['N'];
                let message_count = res.data.Items[0].message_count['N'];
                this.setState({'message_cap': message_cap});
                this.setState({'message_count': message_count});
                this.setState({'message_total': message_cap - message_count});
            }
        )
            .catch(
                err => {
                    console.log(err)
                }
            )
    }

    render() {
        return (
            <div className="flex flex-wrap">
                <div className="w-full bg-gray-300">
                    You have {this.state.message_total} messages left in your account.
                </div>
            </div>
        );
    }
}
