import {Auth} from  '@aws-amplify/auth'

export const auth = {
    isAuthenticated: getAuthUser(),
    isFirstLogin: false,
    cognitoUser: null,
};

async function getAuthUser(){
    await Auth.currentAuthenticatedUser().then(
      res => {
          return true;
      }
    )
    .catch(
        error => {
            console.log(error);
            return false
        }
    );
}
